module.exports = {
    title: '一家仁h5uat',
    baseUrl: 'https://test.xxx.com', //test 测试项目地址
    baseApi: 'http://tenant-m-uat.yjr99.com', //test 测试api请求地址
    APPID: 'xxx',
    APPSECRET: 'xxx',
    desc: 'uat环境',
    ossUrl: 'https://yjr-prod-op.oss-cn-shanghai.aliyuncs.com/yjr-memberh5',
    shurenH5URL: 'https://pre-business-h5.shurenzhipin.com',
    phpBaseApi: 'https://d.yjr88.com'
}